<template>
  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>About <span role="img" aria-label="about">🙋‍♂️</span></h2>
        <p></p>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <img :src="`${publicPath}img/profile.jpg`" class="img-fluid" alt />
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 content">
          <h3>Software Developer</h3>
          <p
            class="font-italic"
          >👋 Hey, I'm Nigel! I'm a computer science student graduate from Concordia University.</p>
          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Birthday:</strong> 11 Dec 1999
                </li>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Born in:</strong> Mauritius
                </li>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Now in:</strong> Montreal
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Degree:</strong> Computer Science
                </li>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Email:</strong> hey[at]nigelyong[.]com
                </li>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Phone:</strong> +1 438-223-3900
                </li>
              </ul>
            </div>
          </div>
          <p>
            Experienced in building software. When I'm not working on a project, I'm most likely spending time with my friends or family, training for competitive arm wrestling, reading a self-improvement/ psychology book or just chilling not taking life seriously. :)
          </p>
          <form method="get" action="Nigel_Yong_Resume.pdf" class="resume-button">
            <div class="text-center">
              <button style="margin-top:10px" id="submit_form" type="submit">Download Resume</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->
</template>

<script>
export default {
  name: "About",
  props: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #42b983;
}

.resume-button button {
  background: #0563bb;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
</style>