<template>
  <section id="blog" class="blog section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Blog <span role="img" aria-label="project">📝</span></h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      </div>

      <template v-if="posts">
        <div class="card-deck" >
        <div v-for="post in posts.data.posts" :key="post.id" class="card mb-4" style="min-width: 18rem;">
          <img
                  class="d-block w-100"
                  :src=post.feature_image
                  alt="Post thumbnail"
                />
          <div class="card-body text-center">
            <h5 class="card-title">{{post.title}}</h5>
            <p
              class="card-text"
            >{{post.excerpt | str_limit(100) }}</p>
            
          </div>
          <div class="card-footer text-right">
            <a class="stretched-link" :href=post.url style="white!important"></a>
            <small class="text-muted">{{post.reading_time}} min read</small>
          </div>
        </div>
      </div>
      </template>
      
      <div class="text-center">
        <button onclick="location.href='https://blog.nigelyong.com/'" type="button">Checkout my blog</button>
      </div>
      

    </div>
  </section>
  <!-- End Portfolio Section -->
</template>

<script>

import axios from 'axios';

export default {
  name: "Blog",
  props: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      posts: null
    };
  },
  created () {
    axios
      .get('https://blog.nigelyong.com/ghost/api/v3/content/posts/?key=633dfebf04ea25b7eff2fe1ad5&limit=3&order=published_at%20desc')
      .then(response => (this.posts = response))
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #42b983;
}

/* .card:hover{
  transform: scale(1.00);
  border:black;
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
} */

.card-footer a {
  background: #0563bb;
  color: #fff;
}

.card img {
  height: 220px
}


button {
  background: #0563bb;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
</style>