<template>
  <section id="portfolio" class="portfolio section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Portfolio <span role="img" aria-label="project">📂</span></h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      </div>

      <!-- <div class="row">
        <div
          class="col-lg-12 d-flex justify-content-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">All</li>
            <li data-filter=".filter-app">App</li>
            <li data-filter=".filter-card">Card</li>
            <li data-filter=".filter-web">Web</li>
          </ul>
        </div>
      </div> -->

      <div class="card-deck">
        <div v-for="project in projects" :key="project.index" class="card mb-4" style="min-width: 18rem;">
          <div :id=project.index class="carousel slide carousel-fade carousel-sync" data-ride="carousel">
            <div class="carousel-inner">
              <div v-for="(image, index) in project.images" :key="image" :class="{ 'active': index === 0 , 'carousel-item' : true}">
                <img
                  class="d-block w-100"
                  :src=image
                  alt="First slide"
                />
              </div>
            </div>
          </div>
          <div class="card-body text-center">
            <h5 class="card-title">{{project.title}}</h5>
            <p
              class="card-text"
            >{{project.description}}</p>
          </div>
          <div class="card-footer text-center">
            
            <a v-if="project.links.code" :href=project.links.code class="btn btn-sm btn-dark float-left"><span class="title">&lt;/&gt;</span> Code</a> 
            <a v-if="project.links.demo" :href=project.links.demo class="btn btn-sm btn-dark float-right"><span role="img" aria-label="demo">💻</span> Demo</a> 
            <a v-if="project.links.report" :href=project.links.report class="btn btn-sm btn-dark float-right"><span role="img" aria-label="demo">📝</span> Report</a> 
            <!-- <small class="text-muted">Last updated 3 mins ago</small> -->
          </div>
        </div>
      </div>
      <div class="text-center">
        <button onclick="location.href='https://github.com/niyonx'" type="button">See more on GitHub</button>
      </div>
    </div>
  </section>
  <!-- End Portfolio Section -->
</template>

<script>
export default {
  name: "Portfolio",
  props: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      projects: [
        {
          index: 1,
          title: "Foodbook",
          description:
            "Foodbook is a web app that gives you your most wanted food recipies from just a picture of your food. Developed in ConUHacks V, our team won the SAP challenge.",
          technologies: ["Flask", "Bootstrap", "Google Vision API"],
          images: [process.env.BASE_URL+"img/portfolio/foodbook-1.png", process.env.BASE_URL+"img/portfolio/foodbook-2.png", process.env.BASE_URL+"img/portfolio/foodbook-3.png"],
          links: {
            code: "https://github.com/niyonx/Foodbook",
            demo: "https://devpost.com/software/foodbook-124ebm"
          },
        },
        {
          index: 2,
          title: "Off-work",
          description:
            "A leave management system that allows employees to request vacations, managers to approve or deny leaves and see an overview of who in the company is on vacation and when. Etc.",
          technologies: ["PHP", "Bootstrap"],
          images: [process.env.BASE_URL+"img/portfolio/off-work-1.png", process.env.BASE_URL+"img/portfolio/off-work-2.png"],
          links: {
            code: "https://github.com/niyonx/off-work",
          },
        },
        {
          index: 3,
          title: "Eternity Calculator",
          description:
            "A web application that calculates it's scientific functions without the use of any math libraries.",
          technologies: ["Vue", "Flask"],
          images: [process.env.BASE_URL+"img/portfolio/eternity-1.png"],
          links: {
            code: "https://github.com/niyonx/eternity",
            demo: "https://eternity-app.herokuapp.com/",          
          },
        },
        {
          index: 4,
          title: "Face Classification",
          description:
            "Age, Gender and Race Face Classification with conventional models, CNNs and Transfer Learning.",
          technologies: ["ML", "TensorFlow", "Scikit-learn"],
          images: [process.env.BASE_URL+"img/portfolio/face-classification-1.png"],
          links: {
            code: "https://github.com/niyonx/Face-Classification",
            report: "https://github.com/niyonx/Face-Classification/blob/main/Report.pdf",
          },
        },
        {
          index: 5,
          title: "AI-Generated Zoology",
          description:
            "A Pix2Pix implementation with TensorFlow. Uses cGANs to convert sketches to realistic dogs and cats images!",
          technologies: ["DL", "TensorFlow", "cGANs"],
          images: [process.env.BASE_URL+"img/portfolio/ai-generated-zoology-1.png", process.env.BASE_URL+"img/portfolio/ai-generated-zoology-2.png"],
          links: {
            code: "https://github.com/niyonx/AI-Generated-Zoology",
            report: "https://github.com/niyonx/AI-Generated-Zoology/blob/main/ProjectReport.pdf",   
          },
        },
        {
          index: 6,
          title: "CopTheLook",
          description:
            "Outfit analyzer that will segment and show different constituents of an outfit, like cardigan or shoes, along with potential websites where you can buy those individual outfit components.",
          technologies: ["Vue", "Flask", "Google Vision API", "Samasource Segmenter"],
          images: [process.env.BASE_URL+"img/portfolio/copthelook-1.jpg"],
          links: {
            code: "https://github.com/niyonx/CopTheLook",
            demo: "https://devpost.com/software/shopthelook",          
          },
        },
        {
          index: 7,
          title: "Predictive Maintenance",
          description:
            "Failure prediction on time series data. We did some EDA and feature engineering and implemented created models with Random Forest, Isolation Forest and XGB Trees.",
          technologies: ["Spark", "Sklearn", "Pandas"],
          images: [process.env.BASE_URL+"img/portfolio/BD-1.png",process.env.BASE_URL+"img/portfolio/BD-2.png",process.env.BASE_URL+"img/portfolio/BD-3.png",process.env.BASE_URL+"img/portfolio/BD-4.png",process.env.BASE_URL+"img/portfolio/BD-5.png"],
          links: {
            code: "https://github.com/niyonx/Predictive-Maintenance",
            report: "https://github.com/niyonx/Predictive-Maintenance/blob/master/SOEN_471_Project.pdf",          
          },
        },
        {
          index: 8,
          title: "Small Sample Learning",
          description:
            "We explore techniques such as meta-transfer learning, transfer learning, Generative Latent Implicit Conditional Optimization (GLICO) and data augmentation using only 100 training samples of CIFAR-10.",
          technologies: ["PyTorch", "Sklearn", "Python"],
          images: [process.env.BASE_URL+"img/portfolio/DL-1.png", process.env.BASE_URL+"img/portfolio/DL-2.png"],
          links: {
            code: "https://github.com/niyonx/Small-Sample-Learning",
            report: "https://github.com/niyonx/Small-Sample-Learning/blob/main/COMP499_ProjectReport.pdf",          
          },
        },
        {
          index: 9,
          title: "CleverICE",
          description:
            "CleverICE helps automate the insurance claim process for the best customer satisfaction! We leverage OCR and OpenAI GPT-3 to automatically extract relevant fields from a claim letter.",
          technologies: ["PyTorch", "Sklearn", "Python"],
          images: [process.env.BASE_URL+"img/portfolio/cleverice-1.png", process.env.BASE_URL+"img/portfolio/cleverice-2.png", process.env.BASE_URL+"img/portfolio/cleverice-3.jpg", process.env.BASE_URL+"img/portfolio/cleverice-4.png", process.env.BASE_URL+"img/portfolio/cleverice-5.png", process.env.BASE_URL+"img/portfolio/cleverice-6.png"],
          links: {
            code: "https://github.com/niyonx/CleverICE",
            demo: "https://devpost.com/software/intact-insurance-challenge",          
          },
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #42b983;
}

/* .card:hover{
  transform: scale(1.00);
  border:black;
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
} */

.card-footer a {
  background: #0563bb;
  color: #fff;
}

.carousel-item img {
  height: 220px
}

button {
  background: #0563bb;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
</style>