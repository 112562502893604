<template>
  <!-- ======= Skills Section ======= -->
  <section id="skills" class="skills section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Skills <span role="img" aria-label="skills">🧰</span></h2>
        <p>Here are the tools and technologies I have worked with!</p>
      </div>

      <div class="nav-center">
        <ul class="skill-nav" style="padding-inline-start: 0px;">
          <li :class="{ active: search === '' }" @click="search = ''">
            <a>All</a>
          </li>
          <li
            :class="{ active: search === val }"
            @click="search = val"
            v-for="val in getSkillTags"
            :key="val"
          >
            <a>{{val}}</a>
          </li>
        </ul>
      </div>

      <div >
        <ul class=" dev-icons">
          <li
            class="software-skill-inline"
            v-for="item in filteredItems"
            :key="item.name"
          >
            <!-- <div class="progress">
              <span class="skill">
                {{item.name}}
                <i class="val">{{ item.percentage }}</i>
              </span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" :style="{width: item.percentage}"></div>
              </div>
            </div>-->
            <img class="" :src="item.img">
            <!-- <i :class="item.logo" aria-hidden="true"></i> -->
            <p>{{item.name}}</p>
          </li>
        </ul>
      </div>
      <!-- <div class="col-lg-12"> -->

      <!-- <div class="col-lg-6">

          <div class="progress">
            <span class="skill">Photoshop <i class="val">55%</i></span>
            <div class="progress-bar-wrap">
              <div class="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>

      </div>-->
      <!-- </div> -->
    </div>
  </section>
  <!-- End Skills Section -->
</template>

<script>
export default {
  name: "Skills",
  props: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      currentSkills: null,
      skills: [
        {
          name: "Python",
          img: process.env.BASE_URL+"img/skill-icons/python.svg",
          tag: "Programming",
        },
        {
          name: "Java",
          img: process.env.BASE_URL+"img/skill-icons/java.svg",
          tag: "Programming",
        },
        
        {
          name: "C++",
          img: process.env.BASE_URL+"img/skill-icons/cpp.svg",
          tag: "Programming",
        },
        {
          name: "HTML",
          img: process.env.BASE_URL+"img/skill-icons/html-5.svg",
          tag: "Web",
        },
        {
          name: "CSS",
          img: process.env.BASE_URL+"img/skill-icons/css.svg",
          tag: "Web",
        },
        { name: "JS",
          img: process.env.BASE_URL+"img/skill-icons/javascript.svg",
          tag: "Web" },
        {
          name: "PHP",
          img: process.env.BASE_URL+"img/skill-icons/php.svg",
          tag: "Web",
        },
        {
          name: "Flask",
          img: process.env.BASE_URL+"img/skill-icons/flask.svg",
          tag: "Web",
        },
        {
          name: "Vue",
          img: process.env.BASE_URL+"img/skill-icons/vue.svg",
          tag: "Web",
        },
        {
          name: "Angular",
          img: process.env.BASE_URL+"img/skill-icons/angular.svg",
          tag: "Web",
        },
        {
          name: "SQL",
          img: process.env.BASE_URL+"img/skill-icons/sql.svg",
          tag: "Web",
        },
        {
          name: "Jupyter",
          img: process.env.BASE_URL+"img/skill-icons/jupyter-icon.svg",
          tag: "ML",
        },
        {
          name: "Numpy",
          img: process.env.BASE_URL+"img/skill-icons/numpy-icon.svg",
          tag: "ML",
        },
        {
          name: "Scikit-learn",
          img: process.env.BASE_URL+"img/skill-icons/sklearn.svg",
          tag: "ML",
        },
        {
          name: "Tensorflow",
          img: process.env.BASE_URL+"img/skill-icons/tensorflow.svg",
          tag: "ML",
        },
        {
          name: "PyTorch",
          img: process.env.BASE_URL+"img/skill-icons/pytorch.svg",
          tag: "ML",
        },
        {
          name: "Digital Ocean",
          img: process.env.BASE_URL+"img/skill-icons/do.svg",
          tag: "Tools",
        },
        {
          name: "Docker",
          img: process.env.BASE_URL+"img/skill-icons/docker.svg",
          tag: "Tools",
        },
        {
          name: "GitHub",
          img: process.env.BASE_URL+"img/skill-icons/github.svg",
          tag: "Tools",
        },
        {
          name: "Google Cloud",
          img: process.env.BASE_URL+"img/skill-icons/google_cloud-icon.svg",
          tag: "Tools",
        },
        {
          name: "Heroku",
          img: process.env.BASE_URL+"img/skill-icons/heroku.svg",
          tag: "Tools",
        },
        {
          name: "Netlify",
          img: process.env.BASE_URL+"img/skill-icons/netlify-icon.svg",
          tag: "Tools",
        },
        {
          name: "Travis CI",
          img: process.env.BASE_URL+"img/skill-icons/travis-ci-icon.svg",
          tag: "Tools",
        },
        {
          name: "Jenkins",
          img: process.env.BASE_URL+"img/skill-icons/jenkins-icon.svg",
          tag: "Tools",
        },
        {
          name: "Linux",
          img: process.env.BASE_URL+"img/skill-icons/linux-icon.svg",
          tag: "Tools",
        },
        {
          name: "AWS",
          img: process.env.BASE_URL+"img/skill-icons/amazon_aws-icon.svg",
          tag: "Now learning",
        },
        {
          name: "Flutter",
          img: process.env.BASE_URL+"img/skill-icons/flutterio-icon.svg",
          tag: "Now learning",
        },
        {
          name: "Postgres",
          img: process.env.BASE_URL+"img/skill-icons/postgresql-icon.svg",
          tag: "Now learning",
        },
      ],
      search: "",
    };
  },
  computed: {
    filteredItems() {
      return this.skills.filter((skills) => {
        return skills.tag.indexOf(this.search) > -1;
      });
    },
    getSkillTags() {
      return new Set(this.skills.map((a) => a.tag));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #42b983;
}

.nav-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  list-style: none;
}

ul li {
  display: inline-block;
}

.software-skill-inline > i {
  color: #1476d9;
}

.software-skill-inline>p {
    color: var(--font-color);
    font-size: 20px;
}

.software-skill-inline {
    display: inline-block;
    margin-right: 40px;
    margin-bottom: 40px;
    position: relative;
}

.software-skill-inline img {
  height: 100px;
  width: 100px;
  position: relative;
}

.dev-icons {
    padding-left: 0;
    list-style: none;
    text-align: center;
}
</style>