<template>
  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <h3>Nigel Yong</h3>
      <!-- <p>Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni eligendi fuga maxime saepe commodi placeat.</p> -->
      <div class="social-links">
        <a href="https://www.facebook.com/niyonx/" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="https://www.instagram.com/niyonx/" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="https://www.linkedin.com/in/niyonx/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
        <a href="https://github.com/niyonx" class="github"><i class="bx bxl-github"></i></a>
      </div>
      <div class="copyright">
        &copy; Copyright 2020 - present 
        <p>All rights reserved.<br><br>
        Adapted in Vue from BootstrapMade design.</p>
        <p></p>
      </div>
    </div>
  </footer><!-- End Footer -->
</template>

<script>
export default {
  name: 'Footer',
  props: {
    
  },
  data () {
  return {
    publicPath: process.env.BASE_URL
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #42b983;
} 
</style>