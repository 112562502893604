<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Header/>
    <particles/>
    <Hero/>
    <main id="main">
      <About/>
      <!-- <Facts/> -->
      <Skills/>
      <Resume/>
      <Portfolio/>
      <Blog/>
      <!-- <Services/> -->
      <Contact/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import About from './components/About'
import Contact from './components/Contact'
// import Facts from './components/Facts'
import Footer from './components/Footer'
import Header from './components/Header'
import Hero from './components/Hero'
import Portfolio from './components/Portfolio'
import Blog from './components/Blog'
import Resume from './components/Resume'
// import Services from './components/Services'
import Skills from './components/Skills'
import Particles from "./components/Particles";

export default {
  name: 'App',
  components: {
    About,
    Contact,
    // Facts,
    Footer,
    Header,
    Hero,
    Portfolio,
    Resume,
    // Services,
    Skills,
    Particles,
    Blog
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

</style>
